@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$tableBorderColorOnYellowBackground: #e4e4e4;

.modal {
  :global {
    .modalDialog {
      max-width: 600px;
    }
  }
}

.dealChangesListContainer {
  background-color: $successBackgroundColor;
  padding: 20px 20px 0;
  max-height: 290px;
  overflow-y: auto;
}

.tranchesChangesListContainer {
  background-color: $yellow;
  margin-top: 10px;
  padding: 20px;

  .tranchesChangesTableContainer {
    tr,
    th {
      background-color: $yellow;
      border-bottom-color: $tableBorderColorOnYellowBackground;
    }
  }
}

.tranchesChangesTableContainer {
  margin: 0 -20px;

  thead,
  tbody {
    position: relative;
    display: block;
    width: 100%;
  }

  tbody {
    overflow-y: auto;
    max-height: 180px;
  }

  tr {
    width: 100%;
    display: flex;
    align-items: center;
  }

  td,
  th {
    flex-basis: 100%;
    flex-grow: 4;
    display: block;
  }
}

.boldText {
  font-weight: bold;
}

.trancheChangeFromValue {
  color: $secondaryColor;
}

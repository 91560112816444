@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";

.actions {
  margin: 20px 0;
  @include clearfix;
}

.primaryActions {
  float: left;
}

.secondaryActions {
  float: right;
}

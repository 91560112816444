$successBackgroundColor: #f2fff2;
$warningBackgroundColor: #ffeded;
$tableCellBackgroundColor: #f6f6f6;
$simpleWhite: #eee;

$verifiedColor: #4ab675;
$unverifiedColor: #f9be25;
$bannedColor: #cc4d4d;
$unsubscribedColor: #dadada;
$secondaryGreyColor: #c0c0c0;
$tableRowHoverBackgroundColor: #f9f9f9;

$formFieldMaxWidth: 495px;

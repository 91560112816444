@import "@/Framework/GlobalStyles/global.scss";

body {
  background-color: #eee;
}

:global {
  .wrapper-id {
    position: relative;
    width: 85px;
  }

  .roadshow-id-clip {
    display: block;
    max-width: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      display: flex;
      align-items: center;
      position: absolute;
      overflow: visible;
      background-color: #f9f9f9;
      max-width: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      padding: inherit;
      z-index: 1;
    }
  }
}

@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formFileUploadOneFile {
  position: relative;
}

.formFileUploadOneFileStatus {
  position: absolute;
  left: 5px;
  top: 10px;
  display: inline-block;
}

.formFileUploadOneFilePercent {
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.trashIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 3;

  svg {
    fill: $blue;
  }
}

.progressBar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0;
}

.progressBarFilled {
  border-radius: 0;
}

@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$warningColor: #dcb500;

.errorColor {
  background-color: $cancelColor;
  color: #000;
}

.warningColor {
  background-color: $warningColor;
  color: #000;
}

.reloadIcon {
  fill: #fff;
}

.dataTableContainer {
  margin: 20px 0;
}

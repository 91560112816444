@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "../../dealProfile.scss";

.trancheContainer {
  display: inline-block;
  position: relative;
  margin-top: -38px;
  width: 0;
  z-index: 1;

  &.isDragging {
    z-index: 4;
  }

  &.isVisible {
    width: auto;
    max-width: 20%;
    min-width: 180px;
    flex-grow: 1;

    .trancheHeader {
      display: inline-block;
    }

    .trancheBody {
      display: inline-block;
    }
  }

  &.isHoverable {
    &:hover {
      @extend .hoveredTranche;
    }
  }
}

.hoveredTranche {
  background-color: $defaultBackgroundColor;

  &.isDragging {
    &.withStickyHeader {
      .trancheHeader {
        top: 0;
        border-bottom: 0;
      }
    }

    .trancheHeader {
      top: 56px;
      border-bottom: 0;
    }

    .cellSeparator {
      display: none;
    }
  }

  .trancheHeader {
    background-color: $defaultBackgroundColor;
  }

  .deleteTrancheBtnContainer,
  .trancheDragIcon {
    display: flex;
  }
}

.trancheHeader {
  position: sticky;
  top: -20px;
  z-index: 2;
  border-bottom: 1px solid $secondary2Color;
  border-top: 1px solid $secondary2Color;
  display: none;
  height: 38px;
  width: 100%;
  font-size: 10px;
  letter-spacing: 0.05em;
  font-weight: 500;
  text-transform: uppercase;
  color: $secondary3Color;
  background-color: #fff;
  padding: 13px 10px;
  text-align: center;
  white-space: nowrap;
  line-height: 12px;
  transition: top 0.6s, transform 0.6s;

  &.withStickyHeader {
    top: 36px;
  }
}

.trancheDragIcon {
  display: none;
  float: left;
  position: absolute;
  left: 10px;
  top: 10px;
  fill: $secondary2Color;

  &:hover {
    cursor: grab;
  }
}

.trancheLabel {
  display: inline-block;
}

.deleteTrancheBtnContainer {
  position: absolute;
  top: 0;
  right: 10px;
  padding-top: 2px;
}

.trancheBody {
  width: 100%;
  padding: 10px;
  display: none;
}

.trancheCell {
  @extend .smallInputsContainer;
  position: relative;
  height: auto;
  min-height: 50px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }

  > div[class*="_isNarrow"] {
    svg {
      width: 8px;
    }
  }
}

@mixin separator($offset) {
  width: calc(100% + $offset);
  position: absolute;
  margin-left: -30px;
  border-bottom: 1px solid $secondary2Color;
}

.cellSeparator {
  @include separator(10px);
}

.cellSeparatorLast {
  @include separator(40px);
}

.calendarIcon {
  margin-top: -5px;
}

.commentsCell {
  height: 174px;
}

.trancheCellGroup {
  padding-top: 58px;
  &.trancheCellGroupCollapsed {
    .trancheCell {
      display: none;

      &:last-child {
        height: 43px;
      }

      .commentsCell {
        height: 31px;
      }
    }
  }

  &:last-of-type {
    .cellSeparator,
    .cellSeparatorLast {
      display: none;
    }
  }
}

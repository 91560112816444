.baseTable {
  tr {
    white-space: nowrap;

    th {
      text-transform: none;
    }
  }
}

.addFieldToFilter {
  font-size: 17px;
  font-weight: bold;
  position: relative;
  top: 2px;
  margin-right: 5px;
  cursor: pointer;

  &.isDisabled {
    color: #dcdcdc;
    cursor: default;
  }
}

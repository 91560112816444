.breadcrumb {
  color: #000;
  margin-bottom: 10px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
}

.arrowIcon {
  width: 10px;
  height: 10px;
  fill: #aaa;
  line-height: 10px;
  vertical-align: middle;
}

@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$successColor: #90b664;
$errorColor: #db1a33;
$warningColor: #ffad0f;

.formWrp {
  :global {
    .panelGroupItem {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      }
    }

    .panelGroupHeader {
      background-color: #fff;
      padding: 20px 140px 20px 20px;
      color: #2d2d2d;
      font-size: 20px;
      cursor: pointer;
      position: relative;

      &:hover {
        .panelGroupHeaderArrowIcon {
          background-color: #ebebeb;

          i {
            fill: $actionColorHover;
          }
        }
      }
    }

    .panelGroupHeaderActive {
      padding-bottom: 0;
    }

    .panelGroupHeaderArrow {
      position: absolute;
      top: 13px;
      right: 20px;

      .panelGroupHeaderArrowText {
        display: inline-block;
        color: $secondaryColor;
        margin-right: 20px;
        font-size: 14px;
        line-height: 28px;
        vertical-align: top;
      }

      .panelGroupHeaderArrowIcon {
        position: relative;
        display: inline-block;
        padding: 6px;
        border-radius: 3px;
        line-height: 0;
        width: 28px;
        height: 28px;

        i {
          fill: $actionColor;
        }
      }
    }

    .panelGroupContent {
      background-color: #fff;
    }

    .panelGroupContentActive {
      padding-top: 30px;
      padding-bottom: 20px;
    }

  }


  .panelGroupWithTable {
    :global(.panelGroupContentActive) {
      padding-top: 10px;
    }
  }
}

.formLabel {
  display: inline-block;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #8c8c8c;
  line-height: 10px;
  margin-bottom: 8px;
}

.formLabel2 {
  @extend .formLabel;
  margin-bottom: 10px;
}


.formLabelIcon {
  position: relative;
  top: 2px;
  margin-left: 4px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;

    &:hover {
      fill: #2d2d2d;
    }
  }
}

.formError,
.formWarning {
  text-align: left;
  font-size: 11px;
  line-height: 20px;
}

.formError {
  color: $cancelColor;
}

.formWarning {
  color: $warningColor;
}

.formCheckbox {
  margin-bottom: 15px;
}

.formInnerSection {
  margin-top: 15px;
  padding-left: 20px;
}

.borderedSection {
  position: relative;
  padding-top: 30px;
  margin-top: 5px;

  &:after {
    content: '';
    border-top: 1px solid #f3f3f3;
    width: calc(100% + 60px);
    position: absolute;
    height: 1px;
    top: 0;
    left: -40px;
  }
}

.formInput {
  width: 365px;
}

.textLink {
  font-size: 12px;
  color: #c0c0c0;
  margin-top: 5px;
}

.link {
  cursor: pointer;
  color: $actionColor;
  border: 0 !important;
  background: 0;
  outline: 0;
  padding: 0 !important;
  font-weight: normal;
  margin-left: 4px;
  text-decoration: underline;

  &:hover {
    color: $actionColorHover;

    i {
      fill: $actionColorHover;
    }
  }

  i {
    display: inline-block;
    position: relative;
    top: 3px;
    fill: $actionColor;
    margin-right: 8px;
  }
}

.textArea {
  min-height: 108px;
  border-radius: 3px;
}

@import "@/condor/ui/common/Layout/variables.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/spaces.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/UI/Organisms/FinalForm/form.scss";

.pageContent {
  max-width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.stickyHeader {
  height: 56px;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 5;
  box-sizing: border-box;
  padding: 10px 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 36px;
  color: $titleColor;
  opacity: 0;
  transition: opacity 0.6s, transform 0.6s;
  transform: translateY(-56px);
  pointer-events: none;

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

.stickyHeaderButtonsContainer {
  display: inline-block;
  float: right;

  button {
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}

.multiValueRemoveIcon {
  width: 8px;
  height: 8px;
}

.formContainer {
  @extend .formWrp;

  &.isSecFiling {
    :global {
      .panelGroupHeader {
        cursor: default;
        font-size: 20px;
        line-height: 23px;
        font-family: $titleFontFamily;
        font-weight: normal;
        letter-spacing: -0.02em;
      }
      .panelGroupItem {
        margin-bottom: 0;

        &:hover {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        }
      }
      .panelGroupContentActive {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

.panelHeader {
  font-size: $titleFontFamily;
}

.panelContent {
  position: relative;
  padding: 0 20px;
}

.smallTitle {
  font-size: 14px;
  color: $titleColor;
}

.dealRow {
  display: flex;
  width: 100%;
  gap: 20px;

  > * {
    flex: 1;
  }
}

.textArea {
  height: 122px;
}

.formButtonsContainer {
  width: 100%;
  position: absolute;
  bottom: -80px;
  padding-bottom: 20px;

  button {
    display: inline-block;
  }
}

.calendarIcon {
  margin-top: -5px;
}

@mixin small-inputs($align-append: false) {
  input {
    height: 27px;
  }

  :global {
    .Select--multi .Select-value {
      padding-top: 0 !important;
      line-height: 17px;
    }

    .Select-control {
      height: 28px;

      .Select-placeholder,
      .Select-value {
        line-height: 17px;
        text-align: left;
        padding-top: 6px;
      }
    }

    .Select-input {
      height: 27px;

      input {
        padding: 0;
        line-height: 28px;
      }
    }

    @if $align-append {
      [class*="appendComponent"] {
        top: -5px;
      }
    }
  }
}

.smallInputsContainer {
  @include small-inputs();
}

.smallInputsContainerAligned {
  @include small-inputs(true);
}

.requiredField {
  input {
    background-color: $yellow;
  }

  :global {
    .Select {
      .Select-control,
      &.is-focused > .Select-control,
      [class$="-control"] {
        background-color: $yellow;
      }
    }
  }
}

.copyButton {
  margin-left: 8px;

  i {
    width: 12px;
    height: 12px;
    margin-bottom: 2px;
  }
}

.trancheAmountInput {
  input {
    min-width: 50px;
  }
}

.textEditorContainerLabel {
  position: absolute;
  margin-bottom: -10px;
}

.fieldTrashIcon {
  position: absolute;
  top: 2px;
  right: 0;
}

.fieldsForm {
  padding: 5px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdcdc;
}

.fieldsFormLoWrapper {
  width: 80px;
  margin: 0 auto;
}

.formGroupFieldActions {
  margin: 10px;
}

.formGroupLoWrapper {
  width: 80px;
  margin: 5px 0;
}

.formGroupField {
  display: table;
}

.formGroupFieldWrapper {
  margin-right: 10px;
  float: left;
}

.formGroupFieldLoWrapper {
  float: left;
  margin-right: 10px;
  width: 65px;
}

.formGroupFieldInput {
  border-left-width: 0;
  width: 300px;
}

.formGroupFieldLabel {
  display: table-cell;
  vertical-align: middle;
}

.formGroupTrashIcon {
  cursor: pointer;
  position: relative;
}


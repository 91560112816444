@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.formFileUpload {
  overflow: auto;
}

$errorColor: #db1a33;

.preview {
  float: left;
  max-height: 156px;
  background-color: #fff;
  border: 1px dashed $secondary2Color;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  word-wrap: break-word;
  font-size: 10px;
  position: relative;
  text-align: center;
  height: 156px;
  display: block;
  margin-right: 8px;
  min-width: 138px;
  max-width: 300px;
}

.previewName {
  position: absolute;
  top: 7px;
  left: 5px;
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.previewStatus {
  position: absolute;
  left: 5px;
  bottom: 10px;
  max-width: 124px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.previewImg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.previewRemoveIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  z-index: 2;

  i {
    fill: $actionColor;
    width: 10px;
    height: 10px;
  }

  &:hover {
    i {
      fill: $actionColorHover;
    }
  }
}

.previewProgressBar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0;
}

.previewProgressBarFilled {
  border-radius: 0;
}

.dropzone {
  border-radius: 3px;
  border: 1px dashed $secondary2Color;
  outline: none;
  padding-top: 60px;
  padding-bottom: 57px;
  cursor: pointer;
  height: 156px;
  text-align: center;

  &:hover {
    border-color: $secondary3Color;

    .preview {
      border-color: $secondary3Color;
    }
  }
}

.dropzoneTooltip {
  display: block;
}

.isDropZoneError {
  border-color: $cancelColor;
}

.isDropZoneMaxSizeError {
  border-color: $cancelColor;
  border-style: solid;
}

.error {
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  color: $cancelColor;
}

.text {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 3px;

  span {
    font-weight: 500;
  }

  &.isDisabled {
    span {
      color: #8c8c8c;
    }
  }
}

.tip {
  font-size: 11px;
  color: $secondaryColor;
}

.videoTip,
.logoTip {
  text-align: center;
  margin: 7px 0;
}

.logoTip {
  padding-bottom: 7px;
}

.uploadTableWrp {
  padding-top: 8px;
}

.uploadListFileWrp {
  @include clearfix;
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.uploadListFileIcon {
  display: table-cell;
  vertical-align: middle;
  width: 16px;

  i {
    fill: $secondaryColor;
  }
}

.uploadListFileName {
  display: table-cell;
  padding-left: 12px;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploadListProgressBar {
  position: absolute;
  bottom: -8px;
  right: 0;
  left: 28px;
}

.uploadListPercentage {
  color: #8c8c8c;
  position: absolute;
  right: 0;
  top: 0;
}

.uploadListSpinnerWrp {
  position: absolute;
  right: 0;
  top: 0;
}

.modalDisclaimerTextarea {
  min-width: 100%;
  min-height: 440px;
}

.disabled {
  display: table;
  height: 156px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 3px;
  border: 1px dashed $secondary2Color;
}

.disabledText {
  display: table-cell;
  font-size: 14px;
  color: #8c8c8c;
  vertical-align: middle;
  text-align: center;
}

.confirmContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: $secondary5Color;
  width: calc(100% + 40px);
  max-width: 800px;
  height: 100%;
  max-height: 346px;
  padding: 30px 0 50px;
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  height: 100%;
  max-height: 280px;
  margin: 0 73px;
}

.columnTitle {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: center;
  color: $titleColor;
}

.columnFilename {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  margin-top: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $titleColor;
  word-break: break-word;
}

.columnFileSize {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 5px;
  color: $secondary3Color;
}

.arrowIcon {
  position: relative;
  min-width: 16px;
  min-height: 9px;
  margin: auto;
  svg {
    fill: $secondaryColor;
  }
}

.modalPreview {
  border: 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  min-width: 250px;
  max-width: 250px;
  min-height: 175px;
  max-height: 175px;
}

.confirmModal {
  :global(.modalDialog) {
    max-width: 800px;
  }
}


@media (max-width: 767px) {
  .arrowIcon {
    transform: rotate(90deg);
    margin: 33px auto 23px;
    width: 9px;
    height: 16px;
    margin-top: 30px;
  }
  .confirmContent {
    flex-direction: column;
    align-items: center;
    max-height: inherit;
  }
}

.formFileUploadOneFileName {
  position: absolute;
  left: 5px;
  bottom: 10px;
}

.tooltipContainer {
  width: 230px;
  display: flex;
}

.tooltipInlineFlex {
  display: inline-flex;
}

.previewIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  margin: 67px 0;

  .previewText {
    text-align: center;
    color: $secondary3Color;
  }

  img {
    width: 77px;
    padding-bottom: 20px;
  }
}
